import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Windmill } from '@windmill/react-ui'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import './assets/css/tailwind.output.css'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { SidebarProvider } from './context/SidebarContext'
import ThemedSuspense from './components/ThemedSuspense'
import theme from './theme/theme'
import keycloak, { keycloakInitConfig } from './providers/keycloak'

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakInitConfig}>
    <SidebarProvider>
      <Suspense fallback={<ThemedSuspense />}>
        <Windmill theme={theme}>
          <App />
        </Windmill>
      </Suspense>
    </SidebarProvider>
  </ReactKeycloakProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
